#faqs-page{

  .main-section{
		padding-top: 30px;
		padding-bottom: 40px;

    .col-faq{
      margin-top: 12px;
      margin-bottom: 12px;

      .q{
        margin-bottom: 6px;
        color: #022A56;
        font-size: 1.05rem;
        font-weight: 600;
      }

      .box-answer{
        display: block;
        color: #000000;
        font-size: 0.95rem;
        line-height: 1.2;
        text-align: justify;
      }
    }
  }

}
