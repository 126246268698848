.box-title-s1{
  position: relative;
  display: block;
  padding: 4px 0 6px 18px;
  width: 100%;

  &:before{
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left: 5px solid #000000;
  }

  .title{
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 1;
  }
}

.box-title-s2{
  display: block;
  text-align: center;
  padding-bottom: 18px;

  .title{
    color: #000000;
    font-size: 1.75rem;
    font-weight: 400;
  }
}
