#cases-list-page{

  .top-section{
    padding-top: 30px;
    padding-bottom: 30px;

    .col-title{
      .box-title-s2{
        padding-bottom: 0;
        text-align: left;
      }
    }

    .col-search{
      form{
        position: relative;
        width: 100%;

        .form-control{
          width: 100%;
          height: 38px;
          color: #000000;
          border: 1px solid $border-color-1;
          border-radius: 0;
        }

        .btn-search{
          position: absolute;
          top: 2px;
          right: 2px;
          width: 85px;
          height: 34px;
          color: #ffffff;
          border: 0;
          border-radius: 0;
          background-color: #000000;

          &:hover, &:focus, &:active{
            opacity: 0.85;
          }
        }
      }
    }
  }

  .cases-section{
    padding-bottom: 12px;

    .col-case{
      margin-bottom: 15px;

      .box{
        display: flex;
        flex: 0 0 100%;
        flex-wrap: wrap;
        max-width: 100%;
        padding: 6px 3px 9px 3px;
        color: #000000;
        text-decoration: none !important;
        border: 1px solid $border-color-1;

        &:hover{
          border-color: #000000;
          outline: 1px solid #000;
        }

        .col-notification{
          padding-top: 8px;
          padding-bottom: 8px;
          color: $blue;

          h6{
            font-size: 0.95rem;

            .icon{
              position: relative;
              top: 1px;
              margin-right: 3px;
              font-size: 1.1rem;
            }
          }
        }

        .box-case-info{
          display: flex;
          flex: 0 0 100%;
          flex-wrap: wrap;
          max-width: 100%;
        }

        .col-info{
          margin-top: 6px;
          margin-bottom: 6px;

          .tb{
            display: block;
            font-size: 0.96rem;
            font-weight: 600;
            line-height: 1.0;

            .lab{
              display: inline-block;
              width: 75px;
              font-weight: 400;

              &.date{
                width: 135px;
              }
            }

            .txt{
              display: inline-block;

              &.status{
                color: #4a90e2;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 992px){
      .col-case{
        .box{
          .box-case-info{
            padding-left: 15px;
          }
        }
      }
    }
    @media screen and (max-width: 1199px){
      .col-case{
        .box{
          .box-case-info{
            .tb{
              .lab{
                display: block;
              }
            }
          }
        }
      }
    }
  }

}
