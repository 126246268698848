#about-us-page{

  .main-section{
    position: relative;
    width: 100%;

    .image{
      position: absolute;
      top: 0;
      right: 0;
      width: 33.00%;
      height: 100%;
      background-color: $soft-gray;
    }

    .container{
      >.row{
        min-height: 76vh;
      }

      .col-info{
        padding-top: 30px;
    		padding-bottom: 40px;

        .box-text{
          display: block;
          line-height: 1.3;
          text-align: justify;

        }
      }
    }

    @media screen and (max-width: 991px){
      .container{
        .col-info{
          padding-top: 20px;
          padding-bottom: 30px;
        }
      }

      .image{
        position: relative;
        width: 100%;
        height: 110px;
      }
    }
  }

}
