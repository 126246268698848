#home-page{

	// == Banners ==
	.banner-section{
		padding: 0;

		.swiper-container{
			// box-shadow: 0 3px 5px rgba(0,0,0,0.4);

			.swiper-wrapper{
				.swiper-slide{
					.swiper-el-con{
						position: relative;
						display: block;
						height: 500px;
					}

					.box-content{
						position: absolute;
						@include flex-center-xy();
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

						.inside{
							display: inline-block;
							padding: 0 15px;
							width: 100%;
							color: #ffffff;
							text-align: center;

							h2{
								margin-bottom: 17px;
								font-size: 4.2rem;
								font-weight: 700;
								letter-spacing: 1px;
								line-height: 1;
								text-shadow: 1px 1px 3px rgba(0,0,0,0.35);
							}

							h4{
								margin-bottom: 13px;
								font-size: 2.5rem;
								font-weight: 500;
								text-shadow: 1px 1px 2px rgba(0,0,0,0.25);
							}

							._btn{
								display: inline-block;
								padding: 12px 20px;
								margin: 5px 9px;
								min-width: 370px;
								font-weight: 500;
								letter-spacing: 2px;
								text-align: center;
								text-decoration: none !important;
								border-radius: 9px;
								line-height: 1.3;

								&:hover{
									opacity: 0.9;
								}

								&.bg-white{
									color: #000000;
									background-color: #ffffff;
								}
								&.bg-blue{
									color: #ffffff;
									background-color: $blue;
								}
							}
						}
					}
				}

				@media screen and (max-width: 575px){
					.swiper-slide{
						.box-content{
							.inside{
								h2{
									font-size: 3.3rem;
								}
								h4{
									font-size: 1.8rem;
									font-weight: 600;
									line-height: 1.2;
								}

								._btn{
									margin: 5px 0px;
									min-width: 100%;
									font-weight: 600;
								}
							}
						}
					}
				}
			}

			.swiper-button-prev, .swiper-button-next{
				color: #ffffff;
			}
		}

		.swiper-desktop{}
		.swiper-mobile{
			display: none;
		}

		@media screen and (max-width: 991px){
			.swiper-desktop{
				display: none;
			}
			.swiper-mobile	{
				display: block;
			}
		}
	}
	// == ==

	// == About ==
	.about-section{
		padding-top: 37px;
		padding-bottom: 37px;

		.col-info{
			.box-text{
				display: block;
				padding: 30px 0;
				text-align: justify;
				font-size: 0.95rem;
				font-weight: 500;
				line-height: 1.35;
			}
		}

		.col-logo{
			text-align: center;
			line-height: 1.3;

			img{
				max-width: 95%;
			}
		}

		@media screen and (max-width: 575px){
			.col-logo{
				img{
					max-width: 85%;
				}
			}
		}
	}
	// == ==


	// == Benefits ==
	.benefits-section{
		position: relative;
		padding-top: 38px;
		padding-bottom: 38px;
		background-color: $blue;

		.container{
			color: #ffffff;

			.col-title{
				margin-bottom: 20px;

				.box-title-s1{
					&:before{
						border-color: #ffffff;
					}
				}
			}

			.col-box{
        padding: 10px 15px;

        .inside{
          display: inline-block;
          padding: 26px 25px 26px 25px;
          // color: #ffffff;
          color: #000000;
          width: 100%;
          height: 100%;
          // border: 2px solid $purple;
          // border-radius: 6px;
          // background-color: $purple;
          // background: linear-gradient(0deg, #1e3661 0%, #144e9b 100%);
          text-decoration: none !important;
          @include transition(250ms);
					border-radius: 14px;
					background-color: #ffffff;

          &:hover{
            opacity: 0.90;
          }

          .box-image{
            display: inline-block;
            width: 100%;
            height: 80px;
            margin-bottom: 13px;
            text-align: center;

            img{
              max-width: 90%;
              max-height: 80px;
            }
          }

          .box-text{
            width: 100%;
            text-align: center;
            @include flex-center-xy();
            text-transform: uppercase;
            flex: 0 0 100%;
            max-width: 100%;
            min-height: 48px;

            div{
              display: inline-block;
              width: 100%;

              h6{
                font-size: 1.25rem;
                line-height: 1.2;
                font-weight: 500;

                &.blue{
                  color: $blue;
                }
              }
            }
          }
        }
      }
		}
	}
	// == ==

	// == Blog ==
	.blog-section{
		position: relative;
		padding-top: 38px;
		padding-bottom: 38px;

		.col-title{
			margin-bottom: 20px;
		}
	}
	// == ==

	// == Info ==
	.info-1-section{
		position: relative;
		padding: 50px 0;
		color: #ffffff;
		text-align: center;
		background-color: $blue-light;

		.container{
			line-height: 1.35;

			.section-title{
				margin-bottom: 20px;
				font-size: 1.70rem;
				font-weight: 600;
				line-height: 1.05;
			}

			.btn{
				padding: 0.6rem 0.75rem 0.55rem 0.75rem;
				width: 170px;
				color: #ffffff;
				font-size: 1.1rem;
				font-weight: 600;
				letter-spacing: 2px;
				border: 2px solid #ffffff;
				border-radius: 0;

				&:hover{
					color: $blue-light;
					background-color: #ffffff;
				}
			}
		}
	}
	// == ==
}
