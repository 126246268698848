#services-page{

  .main-section{
    padding-top: 30px;
		padding-bottom: 30px;

    >.row{
      min-height: 45vh;
      align-items: center;
    }

    .p-lg{
      font-size: 1.05rem;
      line-height: 1.3;
    }

    .box-services{
      margin-top: 30px;

      .col-service{
        margin-bottom: 15px;

        .box{
          display: inline-block;
          width: 100%;
          border: 3px solid #000000;
          border-radius: 10px;

          .box-image{
            display: block;
            padding-top: 36px;
            padding-bottom: 20px;
            text-align: center;

            img{
              max-width: 56%;
            }
          }

          .box-text{
            display: flex;
            align-items: center !important;
            flex: 0 0 100%;
            max-width: 100%;
            min-height: 45px;
            text-align: center;
            margin-bottom: 25px;

            .inside{
              display: inline-block;
              padding-left: 22px;
              padding-right: 22px;
              width: 100%;
              text-align: center;

              h5{
                font-size: 1.30rem;
                font-weight: 600;
                line-height: 1.1;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 992px){
      .box-services{
        margin-left: -8px;
        margin-right: -8px;

        .col-service{
          flex: 0 0 20%;
          max-width: 20%;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
    @media screen and (max-width: 991px){
      .box-services{
        .col-service{
          margin-bottom: 30px;
        }
      }
    }
    @media screen and (max-width: 575px){
      .box-services{
        .col-service{
          margin-bottom: 15px;
        }
      }
    }
  }

}
