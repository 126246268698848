#case-info-page{
  padding-top: 30px;
  padding-bottom: 30px;

  .top-section{
    margin-bottom: 22px;

    .col-left{
      .txt-breadcrumb{
        margin-bottom: 3px;
        color: #454648;
        font-size: 0.80rem;
        letter-spacing: 1px;
      }

      .case-title{
        margin-bottom: 2px;
        font-size: 1.40rem;
      }

      .case-id{
        font-size: 1.20rem;
      }
    }

    .col-right{
      text-align: right;

      .box-notifications-messages{
        position: relative;
        padding: 0 6px;
        margin-bottom: 4px;

        .box-icon{
          position: relative;
          display: inline-block;
          margin: 0 11px;
          color: #f7a545;
          text-decoration: none !important;
          line-height: 1;
          cursor: pointer;
          @include transition(250ms);

          &.msg{
            top: 1px;
          }

          &:first-child{
            margin-left: 0;
          }
          &:last-child{
            margin-right: 0;
          }

          &:hover{
            opacity: 0.85;
          }

          .icon{
            font-size: 1.8rem;
          }

          .num{
            position: absolute;
            top: -5px;
            right: -11px;
            width: 24px;
            height: 24px;
            color: #ffffff;
            line-height: 1;
            font-family: "Arial";
            font-size: 0.70rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.8;
            text-align: center;
            border: 2px solid #fff;
            border-radius: 50%;
            background-color: $blue;
          }
        }
      }

      .box-arbitro{
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid #e3e1e1;

        .col-photo{
          display: flex;
          flex: 0 0 42px;
          max-width: 42px;
          padding: 4px 0;
          justify-content: center;

          .box-photo{
            display: flex;
            flex: 0 0 42px;
            max-width: 42px;
            height: 42px;
            border: 1px solid #a2a5aa;
            border-radius: 50%;
          }
        }

        .col-text{
          padding-left: 0;
          text-align: right;

          h6{
            margin-bottom: 2px;
            color: #9B9B9B;
            font-size: 0.75rem;
            line-height: 1;
          }

          h5{
            font-size: 0.95rem;
            line-height: 1;
          }
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-left{
        .case-title{
          font-size: 1.15rem;
          font-weight: 700;
          line-height: 1.1;
        }
      }

      .col-right{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        text-align: center;
        align-items: center;

        .box-notifications-messages{
          display: flex;
          flex: 0 0 104px;
          max-width: 104px;
          margin-bottom: 0;
        }

        .box-arbitro{
          flex: 0 0 calc(100% - 105px);
          max-width: calc(100% - 105px);
        }
      }
    }
  }

  .content-section{
    .col-historical{
      .box-basic-info{
        padding: 0 3px 0 3px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 22px;
        border: 1px solid $border-color-1;

        .col-i{
          padding-top: 14px;
          padding-bottom: 13px;
          font-size: 0.96rem;
          font-weight: 600;
          line-height: 1.0;
          border-right: 1px solid $border-color-1;

          &:last-child{
            border-right: 0;
          }

          .lab{
            display: block;
            font-size: 0.85rem;
            font-weight: 400;
          }

          .txt{
            display: inline-block;
          }

          .box-status{
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            flex-wrap: wrap;
            margin-top: 3px;

            .status{
              display: flex;
              padding: 5px 8px 4px 8px;
              margin: 1px 1px;
              color: #4a90e2;
              font-size: 0.80rem;
              border: 1px solid #4a90e2;
              border-radius: 5px;

              &.active{
                color: #ffffff;
                background-color: #4a90e2;
                cursor:pointer;
              }

              &:first-child{
                margin-left: 0;
              }
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
      }

      .box-historical{
        >.col-title{
          margin-bottom: 8px;

          h5{
            font-size: 1rem;
          }
        }

        >.col-move{
          >.box{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 13px 12px 11px 12px;
            margin-bottom: 15px;
            color: #000000;
            text-decoration: none !important;
            border: 1px solid $border-color-1;
            cursor: pointer;

            &:hover{
              border-color: #000000;
              outline: 1px solid #000;
            }

            .col-title{
              padding: 0;
              margin-bottom: 8px;

              .title{
                font-size: 0.85rem;
                text-transform: uppercase;
              }
            }

            .col-txt{
              padding: 0 15px 0 0;
              flex: 0 0 calc(100% - 200px);
              max-width: calc(100% - 200px);
              max-height: 29px;
              font-size: 0.70rem;
              line-height: 1.2;
              text-align: justify;
              overflow: hidden;
            }

            .col-date{
              padding: 0 15px 0 0;
              flex: 0 0 200px;
              max-width: 200px;
              font-weight: 600;
              font-size: 0.88rem;
              text-align: right;
            }
          }
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-historical{
        margin-top: 20px;

        .box-basic-info{
          background-color: rgb(2, 42, 86);
          padding: 10px 0 7px 0;
          color: #fff;

          .col-i{
            padding-top: 4px;
            padding-bottom: 4px;
            border-right: 0;
            font-size: 0.93rem;

            .box-status{
              .status{
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 575px){
      .col-historical{
        .box-historical{
          >.col-move{
            .box{
              .col-title{
                order: 0;
                margin-bottom: 0;
              }

              .col-txt{
                flex: 0 0 100%;
                max-width: 100%;
                order: 2;
              }

              .col-date{
                margin-bottom: 3px;
                flex: 0 0 100%;
                max-width: 100%;
                order: 1;
                font-size: 0.80rem;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

}
