#header{

	.header-top{
		.navbar{
			.navbar-brand{
				img{
					height: 52px;
				}
			}

			#nav-collapse{
				.navbar-nav{
					.nav-item{
						.nav-link{
							@include transition(150ms);
							color: #979797;
							cursor: pointer;
							font-size: 1.15rem;
							font-weight: 500;

							&:hover{
								opacity: 0.8;
							}

							.user-icon{
								position: relative;
								margin-right: 3px;
								top: 2px;
								font-size: 1.45rem;
							}
						}
					}

					.nav-button {
						.nav-link {
							padding-left: 1.1rem;
							padding-right: 1.1rem;
							color: #ffffff;
							font-size: 1.05rem;
							border-radius: 6px;
							background: #979797;
						}
					}

					.nav-network {
						.nav-link {
							padding-top: 0;
							padding-bottom: 0;

							img {
								height: 42px;
							}
						}
					}

					.special-item{
						.nav-link{
							position: relative;
							top: 4px;
							padding-left: 1.2rem;
							padding-right: 1.2rem;
							color: #ffffff;
							font-size: 0.9rem;
							font-weight: 500;
							border-radius: 5px;
							background-color: $blue;
						}
					}

					.dropdown{
						.dropdown-menu{
							padding: 0;
							border-radius: 0;
							// border-color: rgba(255,255,255,0.3);

							li{
								.dropdown-item{
									padding: 0.3rem 1.2rem;
									font-size: 0.9rem;
									border: 1px solid transparent;

									&:hover, &:focus, &:active{
										color: #ffffff !important;
										background-color: #979797 !important;
										border-color: rgba(255,255,255,0.2);
									}
								}

								&.no-hover{
									.dropdown-item{
										&:hover, &:focus, &:active{
											color: #212529 !important;
											border-color: transparent !important;
											background-color: transparent !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) and (max-width: 1199px){
			.navbar{
				#nav-collapse{
					.navbar-nav{
						.simple-item{
							.nav-link{
								padding-right: 0.3rem;
								padding-left: 0.3rem;
								font-size: 1.0rem;
							}
						}

						.nav-button {
							.nav-link {
								padding-left: 0.9rem;
								padding-right: 0.9rem;
								font-size: 1.00rem;
							}
						}

						.nav-network {
							.nav-link {
								img {
									height: 41px;
								}
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px){
			display: none !important;
		}
	}

	.header-bottom{
		position: relative;

		.navbar{
			&.bg-black{
				background-color: $blue;
			}

			.navbar-brand{
				padding-top: 0;
				padding-bottom: 0;

				img{
					height: 36px;
				}
			}

			.navbar-toggler{
				color: #ffffff;
				font-size: 1.6rem;
				border: 0;
			}

			.box-network {
				display: flex;
				margin-left: auto;
				color: #ffffff;

				.btn-network {
					display: inline-block;
					margin: 0 5px;

					.img {
						position: relative;
						margin-top: -2px;
						height: 37px;
					}

					.text {
						display: inline-block;
						padding: 3px 10px;
						min-width: 36px;
						color: $blue;
						font-size: 1.1rem;
						border-radius: 4px;
						background-color: #ffffff;
					}

					@media screen and (max-width: 575px) {
						.img {
							display: none;
						}
					}
					@media screen and (min-width: 576px) and (max-width: 991px) {
						.text {
							display: none;
						}
					}
					@media screen and (min-width: 992px) {
						display: none;
					}
				}
			}

			#nav-collapse{
				justify-content: center;

				.navbar-nav{
					.simple-item{
						.nav-link{
							color: #ffffff;
							cursor: pointer;
							font-size: 0.95rem;
							text-transform: uppercase;

							.icon-sort-down{
								position: relative;
								top: -2px;
								font-size: 90%;
								opacity: 0.75;
							}
						}
					}

					.dropdown{
						.dropdown-menu{
							li{
								.dropdown-item{
									&:hover, &:focus, &:active{
										color: #ffffff !important;
										background-color: #000000 !important;
										border-color: rgba(255,255,255,0.2);
									}
								}

								&.no-hover{
									.dropdown-item{
										&:hover, &:focus, &:active{
											color: #212529 !important;
											border-color: transparent !important;
											background-color: transparent !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px){
			.navbar{
				#nav-collapse{
					.navbar-nav{
						.simple-item{
							margin-left: 17px;
							margin-right: 17px;

							@media screen and (max-width: 1199px){
								margin-left: 5px;
								margin-right: 5px;
							}

							&:first-child{
								margin-left: 0;
							}
							&:last-child{
								margin-right: 0;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px){
			.navbar{
				#nav-collapse{
					padding-top: 6px;

					.navbar-nav{
						.simple-item{
							.nav-link{
								padding-top: 0.20rem;
								padding-bottom: 0.20rem;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}

}
