#contact-page{
  position: relative;
  background-color: $soft-gray;
  background-image: url('../images/pages/contact/background.jpg');

  .form-section{
    padding-top: 22px;
    padding-bottom: 22px;

    .box-form{
      position: relative;
      display: block;
      padding: 32px 50px;
      color: #ffffff;
      background-color: rgba(0,0,0,0.9);

      .title{
        font-size: 1.45rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
      }

      form{
        margin-top: 25px;
      }

      .form-group{
        .form-control, .custom-select{
          padding: 0.375rem 1.2rem;
          height: 44px;
          font-size: 1.1rem;
          font-weight: 500;
          border-radius: 0;
          border-color: #ffffff;
        }

        textarea{
          height: inherit !important;
          resize: none;
        }
      }

      .btn-send{
        padding: 0.7rem 0.95rem;
        width: 290px;
        max-width: 100%;
        font-size: 1.1rem;
        font-weight: 500;
        text-align: center;
        border: 1px solid #ffffff;
        border-radius: 0;
        background-color: transparent;

        &:hover{
          color: #999696;
          border-color: #6c6868;
        }
      }
    }

    @media screen and (max-width: 575px){
      .box-form{
        padding: 22px 24px;

        .btn-send{
          width: 100%;
        }
      }
    }
  }

}
