#user-access-page{
  min-height: 77vh;
  // font-family: "Raleway", sans-serif;
  background-color: #ebecf0;
  // background-image: url('../images/pages/user/x.jpg');

  >.container{
    .btn-blue{
      min-width: 150px;
      color: #ffffff !important;
      border-color: $blue;
      background-color: $blue;
      font-weight: 300;

      &.outline{
        color: $blue !important;
        background-color: transparent !important;
      }
    }

    .btn-green{
      min-width: 150px;
      color: #ffffff;
      border-color: #259f02;
      background-color: #259f02;
      font-weight: 300;

      &.outline{
        color: #259f02 !important;
        background-color: transparent !important;
      }
    }

    >.row{
      padding: 115px 0 100px 0;

      .form-container{
        display: inline-block;
        width: 500px;
        background-color: #fff;

        .box-color{
          position: relative;
          display: inline-block;
          width: 100%;
          height: 75px;
          background-color: #fff;

          i{
            content: " ";
            position: absolute;
            margin-left: -70px;
            top: -80px;
            left: 50%;
            width: 140px;
            height: 140px;
            font-style: normal;
            background-color: $blue;
            border-radius: 50%;

            u{
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -40px;
              width: 100%;
              // content: "\f007";
              text-align: center;
              color: #fff;
              // font-family: "Font Awesome 5 Free";
              font-size: 69px;
              // font-weight: 900;
              line-height: 1;
              text-decoration: none;

              &.folder{
                margin-top: -39px;
                font-size: 77px;
              }
            }
          }
        }

        .form{
          color: #6b6b6b;
          font-size: 0.95rem;
          padding: 0 25px 20px 25px;

          h1{
            color: #838686;
            text-align: center;
            font-weight: 200;
            line-height: 0.9;

            small{
              font-size: 1.6rem;
              font-weight: 300;
            }

            &.md{
              font-size: 2.1rem;
            }
          }

          a{
            color: $blue;
          }

          .form-group{
            margin-bottom: 1.35rem;

            .form-control, .custom-select{
              color: #2f3031;
              font-weight: 400;
              border-color: $blue;
            }

            textarea{
              resize: none;
            }
          }

          .form-errors{
            line-height: 1.3;

            h6{
              font-size: 0.85rem;
              font-weight: 400;
            }

            ul{
              padding-left: 15px;
              li{}
            }
          }
        }
      }
    }
  }
}
