.modal-historical-s1{
  .modal-content{
    border: 0;
    border-radius: 0;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.0rem 0.5rem;
        margin: 0;
        top: 5px;
        right: 7px;
        color: #4f4c4c;
        font-size: 2.8rem;
        font-weight: 300;
        text-shadow: none;
        line-height: 0.7;
        z-index: 20;
      }
    }

    .modal-body{
      padding: 2rem;

      .modal-title{
        margin-bottom: 10px;
        font-size: 1.05rem;
      }

      .modal-date{
        font-size: 0.90rem;
        margin-bottom: 7px;
      }

      .box-info{
        display: block;
        font-size: 0.90rem;
        line-height: 1.2;
      }
    }
  }
}

.modal-notifications-s1{
  .modal-content{
    border: 0;
    border-radius: 0;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.0rem 0.5rem;
        margin: 0;
        top: 5px;
        right: 7px;
        color: #4f4c4c;
        font-size: 2.8rem;
        font-weight: 300;
        text-shadow: none;
        line-height: 0.7;
        z-index: 20;
      }
    }

    .modal-body{
      padding: 1.2rem 2rem 0.9rem 2rem;

      .modal-title{
        margin-bottom: 10px;
        color: $blue;
        font-size: 1.05rem;
      }

      .box-notifications{
        position: relative;

        .box-notification{
          position: relative;
          display: inline-block;
          padding: 13px 12px 11px 12px;
          margin-bottom: 15px;
          width: 100%;
          color: #000000;
          text-decoration: none !important;
          border: 1px solid $border-color-1;
          cursor: pointer;

          &:hover{
            border-color: #000000;
            outline: 1px solid #000;
          }

          .title{
            margin-bottom: 8px;
            font-size: 0.85rem;
            text-transform: uppercase;
          }

          .box-txt{
            position: relative;
            display: block;
            max-height: 42px;
            font-size: 0.70rem;
            line-height: 1.2;
            text-align: justify;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.modal-message-s1{
  .modal-content{
    border: 0;
    border-radius: 0;

    .modal-header{
      position: relative;
      padding: 0;
      border: 0;
      z-index: 5;

      .close{
        position: absolute;
        padding: 0.0rem 0.5rem;
        margin: 0;
        top: 5px;
        right: 7px;
        color: #4f4c4c;
        font-size: 2.8rem;
        font-weight: 300;
        text-shadow: none;
        line-height: 0.7;
        z-index: 20;
      }
    }

    .modal-body{
      padding: 1.2rem 1rem 0.9rem 1rem;

      .modal-title{
        margin-bottom: 10px;
        color: #000000;
        font-size: 1.20rem;
      }
    }
  }
}
