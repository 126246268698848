#how-page{

	.main-section{
		padding-top: 30px;
		padding-bottom: 40px;

		.box-title-s2{
			margin-bottom: 6px;
		}

		.col-desktop{
			img{
				max-width: 100%;
			}
		}

		.col-mobile{
			display: none;
			text-align: center;
			margin-bottom: 15px;

			&:last-child{
				margin-bottom: 0
			}

			img{
				max-width: 100%;
			}
		}

		@media screen and (max-width: 991px){
			.box-title-s2{
				padding-bottom: 4px;
			}
			.col-desktop{
				display: none;
			}
			.col-mobile{
				display: block;

				.img-sobre{
					width: 440px;
				}
			}
		}
	}

}
