#messages-page{

  .messages-section{
    padding-top: 30px;
    padding-bottom: 30px;

    .col-title{
      margin-bottom: 15px;

      .box-title-s2{
        padding-bottom: 5px;
      }
    }

    .col-main-content{
      .box-inside{
        display: flex;
        flex-wrap: wrap;
        border-radius: 3px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
      }

      .col-cases{
        padding-right: 0;
        padding-left: 0;
        overflow: auto;
        overflow-x: hidden;
        max-height: 72vh;

        .box{
          display: block;
          border: 0;
          outline: inherit !important;
        }

        .btn-message{
          position: relative;
          display: block;
          padding: 11px 18px 9px 18px;
          color: #575454;
          font-size: 0.95rem;
          box-shadow: 0 1px 2px rgba(0,0,0,0.10);
          text-decoration: none !important;
          line-height: 1.25;

          span{
            display: block;

            &.case-id{
              color: #131313;
              font-size: 86%;
              font-weight: 700;
            }
          }

          &:hover, &:active, &:focus{
            color: #000000;
            font-weight: 500;
            background-color: rgba(0,0,0,0.02);
          }

          &.active, &.router-link-exact-active{
            color: #ffffff !important;
            font-weight: 500 !important;
            background-color: $blue !important;

            span{
              color: #ffffff !important;
            }
          }
        }
      }

      .col-message{
        padding: 0;
        min-height: 73vh;
        border-left: 1px solid #e3e1e1;

        .box-note{
          display: block;
          padding: 40px 15px;
          color: #68696a;
          text-align: center;

          .icon{
            margin-bottom: 9px;
            font-size: 5.5rem;
          }

          h5{
            font-weight: 600;
          }
        }

        .page-content{
          display: block;
        }
      }
    }

    @media screen and (max-width: 991px){
      .col-main-content{
        .col-cases{
          border-bottom: 2px solid #343535;
          max-height: 125px;
        }

        .col-message{
          min-height: inherit;

          .page-content{
            .col-msg{
              .overflow{
                max-height: 48vh;
              }
            }
          }
        }
      }
    }
  }

}

.box-messages-template{
  .box-arbitro{
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #e3e1e1;

    .col-arbitro-info{
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      align-items: center;
    }

    .col-case{
      a{
        color: $blue-light;
        font-weight: 700;
      }
    }

    .col-photo{
      display: flex;
      flex: 0 0 75px;
      max-width: 75px;
      padding: 10px 6px;
      justify-content: center;

      .box-photo{
        display: flex;
        flex: 0 0 42px;
        max-width: 42px;
        height: 42px;
        border: 1px solid #a2a5aa;
        border-radius: 50%;
      }
    }

    .col-text{
      padding-left: 0;

      h6{
        margin-bottom: 2px;
        color: #9B9B9B;
        font-size: 0.75rem;
        line-height: 1;
      }

      h5{
        font-size: 0.95rem;
        line-height: 1;
      }
    }
  }

  .box-form{
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
    border-bottom: 1px solid #e3e1e1;

    .col-form{
      padding-top: 12px;
      padding-bottom: 12px;

      form{
        position: relative;

        .btn-file{
          color: $blue;
          cursor: pointer;
          text-decoration: none !important;

          &:hover{
            opacity: 0.85;
          }
        }

        .box-shad{
          position: relative;
          display: block;
          width: 100%;
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.3) !important;
          border-radius: 6px;
        }

        .form-control{
          padding-right: 85px;
          // height: 42px;
          font-size: 0.95rem;
          border: 0;
          background-color: transparent !important;
          resize: none;
        }

        .btn-send{
          position: relative;
          margin-top: 4px;
          // right: 4px;
          width: 100%;
          height: 34px;
          color: #ffffff !important;
          font-size: 0.85rem;
          border: 4px;
          background-color: $blue;
        }
      }
    }
  }

  .box-messages{
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;

    .col-msg{
      padding-top: 15px;
      padding-bottom: 15px;

      .overflow{
        display: block;
        padding-top: 3px;
        padding-bottom: 3px;
        overflow: auto;
        overflow-x: hidden;
        max-height: 56vh;
      }

      .box-msg{
        display: block;
        padding: 9px 5px 23px 5px;
        text-align: right;

        a.bubble{
          text-decoration: none !important;
          @include transition(200ms);

          &:hover{
            opacity: 0.90;
          }
        }
        .bubble{
          position: relative;
          display: inline-block;
          padding: 10px 20px 10px 20px;
          min-width: 100px;
          max-width: 96%;
          min-height: 41px;
          color: #000000;
          font-size: 0.90rem;
          line-height: 1.4;
          text-align: right;
          box-shadow: 0 0 4px rgba(0,0,0,0.4);
          border-radius: 10px;
          background-color: transparent;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
          word-break: break-word;

          .date{
            position: absolute;
            bottom: -23px;
            right: 0;
            color: #575454;
            font-size: 0.7rem;
            font-style: normal;
            line-height: 1;
            pointer-events: none;
          }
        }

        &.me{
          text-align: left;

          .bubble{
            color: #ffffff;
            background-color: $blue-light;
            box-shadow: none;
            text-align: left;

            .date{
              right: inherit;
              left: 0;
              bottom: -19px;
            }
          }
        }
      }
    }
  }
}
