.custom-group-s1{
  legend, label{
    color: #030d17;
    padding-bottom: 4px;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.15;
  }

  >div{
    .form-control, .custom-select{
      border-color: #a3a4a6 !important;
    }

    .custom-select{
      option{
        &:first-child{
          color: #999999;
        }
      }
    }
  }

  &.group-payment-exp{
    >div{
      display: flex;
      flex: 0 0 100%;
      max-width: 100%;
      flex-wrap: wrap;
      border: 1px solid #a3a4a6;
      border-radius: 0.25rem;
      align-items: center;

      .month{
        display: flex;
        padding: 0.375rem 0.4rem 0.375rem 0.3rem;
        flex: 0 0 47px;
        max-width: 47px;
        text-align: right;
        border: 0 !important;
        background-image: none;
      }

      .sep{
        padding: 0;
        flex: 0 0 10px;
        max-width: 10px;
        font-size: 1.2rem;
        font-weight: 300;
      }

      .year{
        display: flex;
        padding: 0.375rem 0.3rem 0.375rem 0.3rem;
        flex: 0 0 70px;
        max-width: 70px;
        text-align: left;
        border: 0 !important;
        background-image: none;
      }
    }
  }
}

.c-radios-group-s1{
  >div{
    .radios{
      display: flex;
      flex-wrap: wrap;
      margin-left: -7px;
      margin-right: -7px;
      // flex: 0 0 100%;
      // max-width: 100%;

      .custom-radio{
        padding-left: 7px;
        padding-right: 7px;
        margin: 0;

        .custom-control-label{
          padding: 11px 15px 9px 15px;
          width: 100%;
          text-align: center;
          border: 1px solid $blue;
          border-radius: 0.25rem;
          cursor: pointer;
          text-transform: uppercase;

          &:before, &:after{
            display: none !important;
          }
        }

        .custom-control-input:checked ~ .custom-control-label{
          color: #ffffff;
          background-color: $blue;
        }

      }
    }
    .radiosdos{
      display: flex;
      flex-wrap: wrap;
      margin-left: -7px;
      margin-right: -7px;
      // flex: 0 0 100%;
      // max-width: 100%;

      .custom-radio{
        padding-left: 7px;
        padding-right: 7px;
        margin: 0;

        .custom-control-label{
          padding: 11px 15px 9px 15px;
          width: 100%;
          text-align: center;
          border: 1px solid $blue;
          border-radius: 0.25rem;
          
          text-transform: uppercase;

          &:before, &:after{
            display: none !important;
          }
        }

        .custom-control-input:checked ~ .custom-control-label{
          color: #ffffff;
          background-color: $blue;
        }

      }
    }
  }
}
